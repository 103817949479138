import { createGroupsAppController } from '@wix/social-groups-app/dist/src/controllers/appController';
import { createGroupsWidgetController } from '@wix/social-groups-app/dist/src/controllers/widget/GroupsWidgetController';
import { createAppController } from '@wix/social-group/dist/src/controllers/createAppController';
import {
  IAppData,
  IPlatformAPI,
  IWidgetControllerConfig,
} from '@wix/native-components-infra/dist/src/types/types';
import { EXPERIMENTS_SCOPE } from './app-configs';
import Experiments, { ExperimentsBag } from '@wix/wix-experiments';
import { GROUPS_WIDGET_ID } from '@wix/social-groups-app/dist/src/config/constants';
import { GROUP_WIDGET_ID } from '@wix/social-group/dist/src/config/constants';
export const GROUPS_LIST_WIDGET = '0a9f687f-7e00-4576-a8e1-9415844b8f44';

export const GROUPS_WIDGET_OLD = 'a1f0f11f-2c2d-40c9-b42e-1ce811d15465';
export const GROUP_WIDGET_OLD = '49acda86-4503-46bf-8418-89616e71d45b';
export const GROUPS_LIST_WIDGET_TEST = '65fdd948-296f-42a5-86fa-7ca750b664f8';

const controllerByType = {
  [GROUPS_WIDGET_ID]: createGroupsAppController,
  [GROUP_WIDGET_ID]: createAppController,
  [GROUPS_LIST_WIDGET]: createGroupsWidgetController,
  [GROUPS_WIDGET_OLD]: createGroupsAppController,
  [GROUP_WIDGET_OLD]: createAppController,
  [GROUPS_LIST_WIDGET_TEST]: createGroupsWidgetController,
};
const testWidgets = [
  GROUPS_WIDGET_OLD,
  GROUP_WIDGET_OLD,
  GROUPS_LIST_WIDGET_TEST,
];
let experimentsBag: ExperimentsBag;

async function getExperimentsByScope(scope: string) {
  const experiments = new Experiments({
    scope,
    // We need the full url due to SSR
    // and "https" seems to be the most common way to fetch the specs
    baseUrl: 'https://www.wix.com',
  });
  await experiments.ready();
  return experiments.all();
}

function getExperimentsFromCache(platformApis: IPlatformAPI) {
  try {
    const cachedExperiments = platformApis.storage.memory.getItem(
      EXPERIMENTS_SCOPE,
    );
    if (cachedExperiments) {
      return JSON.parse(cachedExperiments);
    }
  } catch (e) {
    console.log('Error getting experiments from memory cache');
    return;
  }
}

export const initAppForPage = async (
  initParams: IAppData,
  platformApis: IPlatformAPI,
  wixCodeApi,
  platformServicesApis,
) => {
  experimentsBag =
    getExperimentsFromCache(platformApis) ||
    (await getExperimentsByScope(EXPERIMENTS_SCOPE));
  platformApis.storage.memory.setItem(
    EXPERIMENTS_SCOPE,
    JSON.stringify(experimentsBag),
  );
};

export const createControllers = (
  controllerConfigs: IWidgetControllerConfig[],
) => {
  return controllerConfigs.map(config => {
    const widgetId = config.type;
    if (testWidgets.includes(widgetId)) {
      console.warn(
        `This is a TEST install. Widget ${widgetId} is used mostly locally`,
      );
    }
    const createController = controllerByType[widgetId];
    if (createController) {
      return createController(config, experimentsBag);
    }
  });
};
